<!--suppress JSUnresolvedVariable -->
<template>
  <div>
    <v-form ref="form">
      <v-text-field
        v-model="user.name"
        outlined
        label="Nome"
        :rules="[$rules.required]"
        maxlength="150"
      />
      <v-text-field
        v-model="user.mail"
        outlined
        label="E-mail"
        :rules="[$rules.email]"
        maxlength="150"
      />
      <template v-if="!modeEdit">
        <v-text-field
          v-model="user.password"
          type="password"
          outlined
          label="Senha"
          maxlength="16"
          :rules="[$rules.required, $rules.minLengthPassword]"
          autocomplete="new-password"
        />
        <v-text-field
          v-model="user.confirmPassword"
          type="password"
          outlined
          label="Confirme sua senha"
          :rules="[matchPassword]"
          autocomplete="new-password"
        />
      </template>
      <label for="roles">
        Seleciona a opção mais adequada para seu objetivo:
      </label>
      <div id="roles">
        <v-checkbox
          v-model="user.roles"
          label="Sou terapeuta: desejo realizar terapias para meus pacientes"
          value="therapist"
          hide-details
          :rules="[$rules.arrayNotEmpty]"
        />
        <v-checkbox
          v-model="user.roles"
          label="Sou paciente: desejo ver as minhas terapias realizadas e agendar terapias com terapeutas"
          value="patient"
          hide-details
          :rules="[$rules.arrayNotEmpty]"
        />
      </div>

      <v-row v-if="!modeEdit" class="terms">
        <h3 style="text-align: center">Termos e condições</h3>
        <a
          href="https://s3.amazonaws.com/files.prod.appbio/APPBIO_Termos_e_Condi%C3%A7%C3%B5es_Gerais_de_Uso_e_ou_de_VendaV2.pdf"
          target="_blank"
          >Ler Termos de Uso</a
        >
        <a
          href="https://s3.amazonaws.com/files.prod.appbio/APPBIO_Pol%C3%ADtica_de_PrivacidadeV2.pdf"
          target="_blank"
          >Ler Política de Privacidade</a
        >
      </v-row>

      <v-row v-if="!modeEdit" style="margin-bottom: 20px; padding: 0 10px">
        <v-checkbox
          v-model="user.acceptedTerms"
          label="Li e aceito os termos de uso e a política de privacidade"
          hide-details
          :rules="[$rules.required]"
        />
      </v-row>

      <div class="d-block mt-5">
        <v-btn
          color="secondary"
          :rounded="buttonRounded"
          block
          large
          @click="submit"
        >
          {{ buttonText }}
        </v-btn>
        <slot name="extra-actions"></slot>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => {} },
    buttonText: {
      type: String,
      required: true,
    },
    buttonRounded: {
      type: Boolean,
      default: false,
    },
    modeEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    user: {
      roles: [],
    },
  }),
  computed: {
    matchPassword() {
      return (
        this.newPassword === this.confirmNewPassword ||
        "Campos de senhas diferentes."
      );
    },
  },
  watch: {
    value() {
      this.updateLocalObject();
    },
  },
  created() {
    this.updateLocalObject();
  },
  methods: {
    updateLocalObject() {
      if (this.value?.name) {
        this.user = this.value;
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.user);
      }
    },
    clear() {
      this.user = {
        roles: [],
      };
    },
  },
};
</script>

<style scoped>
.terms {
  margin-top: 20px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 8px;
}
</style>
